import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import {
  IonAvatar,
  IonChip,
  IonContent,
  IonImg,
  IonLabel,
  IonPage,
  IonText,
} from "@ionic/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import Avatar from "../../components/Avatar";
import ScrollableContent from "../../components/ScrollableContent";
import Letter from "../../data/Letter";
import { Match } from "../../data/Match";
import { getLetters, patchLetter } from "../../datasource/letter-datasource";
import UserContextHook from "../../hooks/UserContextHook";
import { JIBI_BASE_URL } from "../../util/ApiClient";
import { UserContext } from "../../util/BetterDatesApp";
import { formatDateToHumanReadable } from "../../util/date-ext";
import { handleError } from "../../util/error";
import LettersResponse from "../../data/LettersResponse";
import { MatchesResponse } from "../../data/MatchesResponse";

export default function ReadLetter() {
  const params = useParams<ReadLetterProps>();
  const history = useHistory();
  const userContext = useContext(UserContext);
  if (!userContext?.userState.loggedIn) {
    return <></>;
  }
  const [state, setState] = useState<ReadLetterStateType>({ loading: true });
  const savedUser = userContext.userState.savedUser;
  const hasMarkedAsRead = useRef(false);

  const markLetterAsRead = useMarkLetterAsRead(userContext);

  const lettersQuery = useQuery({
    queryKey: ["letters", params.matchId],
    queryFn: () => getLetters(params.matchId, savedUser),
    refetchOnMount: false,
  });

  useEffect(() => {
    if (lettersQuery.isLoading) {
      setState({ loading: true });
    }
  }, [lettersQuery.isLoading]);

  useEffect(() => {
    if (lettersQuery.isError) {
      // TODO: handle error state
      handleError(lettersQuery.error, userContext);
    }
  }, [lettersQuery.isError]);

  useEffect(() => {
    const data = lettersQuery.data;
    if (!data) {
      return;
    }
    const letter = data.letters.find((value) => value.id === params.letterId);
    if (!letter) {
      return;
    }
    setState({
      loading: false,
      letter: letter,
      match: data.match,
    });
    if (
      letter.to === savedUser.user.id &&
      !letter.read &&
      !hasMarkedAsRead.current
    ) {
      hasMarkedAsRead.current = true;
      markLetterAsRead?.mutate({ match: data.match, letter: letter });
    }
    return () => {
      markLetterAsRead?.reset;
    };
  }, [lettersQuery.data]);

  return (
    <IonPage className="flex items-center justify-center bg-gray-50">
      <div className="h-full w-full max-w-md">
        <div className="safe-scroller flex h-full w-full flex-col justify-between">
          <header className="flex h-14 flex-row items-center justify-between px-3">
            <IonChip
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
            >
              <ChevronLeftIcon className="me-1 h-5 w-5 text-gray-500" />
              <IonLabel>Back</IonLabel>
            </IonChip>
          </header>
          <main className="flex h-full w-full flex-col">
            {state.loading ? (
              <div className="flex h-full w-full items-center justify-center">
                <IonText>Loading...</IonText>
              </div>
            ) : (
              <div className="flex h-full w-full flex-col">
                <div className="flex flex-col px-8">
                  <div className="flex flex-row items-center gap-4 py-5">
                    <div className="flex flex-col items-center">
                      <IonAvatar className="h-12 w-12">
                        <Avatar
                          src={`${JIBI_BASE_URL}/files/_pb_users_auth_/${
                            state.letter.from === savedUser.user.id
                              ? savedUser.user.id
                              : state.match.partyTwo.id
                          }/${
                            state.letter.from === savedUser.user.id
                              ? savedUser.user.avatar
                              : state.match.partyTwo.avatar
                          }`}
                          alt={
                            state.letter.from === savedUser.user.id
                              ? `${savedUser.user.name}'s avatar`
                              : `${state.match.partyTwo.name}'s avatar`
                          }
                          rounded={true}
                        />
                      </IonAvatar>
                    </div>
                    <div className="flex w-full flex-row items-center justify-center">
                      <IonImg
                        src="/assets/arrow_right.svg"
                        alt=""
                        className="h-8"
                      />
                    </div>
                    <div className="flex flex-col items-center">
                      <IonAvatar className="h-12 w-12">
                        <Avatar
                          src={`${JIBI_BASE_URL}/files/_pb_users_auth_/${
                            state.letter.from === savedUser.user.id
                              ? state.match.partyTwo.id
                              : savedUser.user.id
                          }/${
                            state.letter.from === savedUser.user.id
                              ? state.match.partyTwo.avatar
                              : savedUser.user.avatar
                          }`}
                          alt={
                            state.letter.from === savedUser.user.id
                              ? `${state.match.partyTwo.name}'s avatar`
                              : `${savedUser.user.name}'s avatar`
                          }
                          rounded={true}
                        />
                      </IonAvatar>
                    </div>
                  </div>
                </div>
                <div className="flex-1 overflow-y-hidden px-4">
                  <IonContent className="h-full" scrollY={false}>
                    <ScrollableContent>
                      <div className="mb-2 mt-4 flex w-full flex-col gap-4">
                        <IonText
                          className="whitespace-pre-wrap text-start text-black"
                          dir="auto"
                        >
                          {state.letter.letter}
                        </IonText>
                        <IonText className="text-sm text-gray-500">
                          {formatDateToHumanReadable(
                            new Date(state.letter.sendDate),
                          )}
                        </IonText>
                      </div>
                    </ScrollableContent>
                  </IonContent>
                </div>
              </div>
            )}
          </main>
        </div>
      </div>
    </IonPage>
  );
}

function useMarkLetterAsRead(userContext: UserContextHook) {
  if (!userContext.userState.loggedIn) {
    return undefined;
  }
  const savedUser = userContext.userState.savedUser;
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (variables: { match: Match; letter: Letter }) => {
      const response = await patchLetter(savedUser, {
        ...variables.letter,
        read: true,
      });

      if (response.status !== 200) {
        throw new Error("Failed to mark letter as read");
      }

      return {
        updatedLetter: {
          ...variables.letter,
          ...response.data.letter,
        } as Letter,
        matchId: variables.match.id,
      };
    },

    onSuccess: async (data, variables) => {
      let hasUnreadLetters = false;
      await queryClient.setQueryData(
        [userContext.queryKeys.letters, variables.match.id],
        (cachedLettersResponse: LettersResponse | undefined) => {
          const cachedLetters = cachedLettersResponse?.letters;
          if (!cachedLetters) return undefined;

          return {
            letters: cachedLetters.map((oldLetter) => {
              const isItTheUpdatedLetter =
                oldLetter.id === data.updatedLetter.id;
              if (
                !isItTheUpdatedLetter &&
                oldLetter.to === savedUser.user.id &&
                !oldLetter.read
              ) {
                hasUnreadLetters = true;
              }
              return isItTheUpdatedLetter ? data.updatedLetter : oldLetter;
            }),
            match: cachedLettersResponse.match,
          };
        },
      );

      if (!hasUnreadLetters) {
        await queryClient.setQueryData(
          [userContext.queryKeys.matches],
          (cachedMatchesReponse: MatchesResponse | undefined) => {
            const cachedMatches = cachedMatchesReponse?.matches;
            if (!cachedMatches) return [];

            return {
              matches: cachedMatches.map((oldMatch) =>
                oldMatch.id === variables.match.id
                  ? { ...oldMatch, hasUnreadLetters: false }
                  : oldMatch,
              ),
            };
          },
        );
      }
    },

    onError: (error) => {
      handleError(error, userContext);
    },
  });
}

interface ReadLetterState {
  loading: boolean;
}

interface ReadLetterStateLoading extends ReadLetterState {
  loading: true;
}

interface ReadLetterStateLoaded extends ReadLetterState {
  loading: false;
  match: Match;
  letter: Letter;
}

type ReadLetterStateType = ReadLetterStateLoading | ReadLetterStateLoaded;

export type ReadLetterProps = {
  matchId: string;
  letterId: string;
};
