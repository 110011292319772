import { MatchesResponse } from "../data/MatchesResponse";
import { SavedUser } from "../hooks/LoginHook";
import { GET, JIBI_BASE_URL } from "../util/ApiClient";

export async function getMatches(
  savedUser: SavedUser,
): Promise<MatchesResponse> {
  return GET({
    url: `${JIBI_BASE_URL}/v1/match/`,
    headers: {
      Authorization: savedUser.token,
    },
  }).then((response) => {
    if (response.status !== 200) {
      return Promise.reject(response);
    }
    return response.data as MatchesResponse;
  });
}
