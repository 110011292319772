import { useContext, useState } from "react";
import { UserContext } from "../../../util/BetterDatesApp";
import Modal, { ModalStateType } from "../../../components/modal/Modal";
import PreferenceExampleModal from "../../../components/modal/PreferenceExampleModal";
import {
  IonButton,
  IonChip,
  IonContent,
  IonLabel,
  IonPage,
  IonText,
  IonTextarea,
} from "@ionic/react";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { useHistory } from "react-router";
import { HttpResponse } from "@capacitor/core";
import { JIBI_BASE_URL, PATCH } from "../../../util/ApiClient";
import { handleError } from "../../../util/error";

export default function DescriptionPreferences() {
  const history = useHistory();
  const userContext = useContext(UserContext);
  const loggedIn = userContext?.userState.loggedIn;
  if (!loggedIn) {
    return <></>;
  }
  const savedUser = userContext.userState.savedUser;
  const [description, setDescription] = useState(
    userContext.userState.savedUser.user.matchPreference?.description || "",
  );
  const [modalState, setModalState] = useState<ModalStateType>({
    isVisible: false,
  });

  const showPreferenceExampleModal = () => {
    setModalState({
      isVisible: true,
      content: <PreferenceExampleModal setModalState={setModalState} />,
      title: "",
    });
  };
  const handleModalDismiss = () => {
    setModalState({
      isVisible: false,
    });
  };

  return (
    <IonPage className="flex items-center justify-center bg-gray-50">
      <div className="h-full w-full max-w-md">
        <div className="safe-scroller flex h-full w-full flex-col justify-between">
          <header className="flex h-14 flex-row items-center justify-between px-3">
            <IonChip
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
            >
              <ChevronLeftIcon className="me-1 h-5 w-5 text-gray-500" />
              <IonLabel>Back</IonLabel>
            </IonChip>
          </header>
          <main className="flex h-full w-full flex-col justify-between px-3 pb-3">
            <IonContent className="h-full w-full" scrollY={true}>
              <div className="mt-4 flex w-full flex-col gap-1 px-3">
                <div className="flex w-full flex-col gap-2">
                  <IonText className="text-2xl font-bold">
                    What and who are you looking for?
                  </IonText>
                  <IonText>
                    Describe in couple of sentences what and who you&apos;re
                    looking for. We use this information to match you with the
                    right person.
                  </IonText>
                  <div className="mt-8 bg-gray-100">
                    <IonTextarea
                      className="w-full border-2 border-dashed align-text-top"
                      placeholder="I'm looking for..."
                      value={description}
                      counter={true}
                      spellcheck={true}
                      maxlength={512}
                      autoGrow={true}
                      color={"dark"}
                      onIonInput={(e) => {
                        const newInput = e.detail.value as string;
                        setDescription(newInput);
                      }}
                    />
                  </div>
                </div>
              </div>
            </IonContent>
            <div className="flex w-full flex-row justify-end">
              <IonButton
                className={"mt-8 h-9 w-20"}
                shape={"round"}
                color={"dark"}
                disabled={description.length === 0}
                size={"small"}
                onClick={(e) => {
                  e.preventDefault();
                  const trimmedDescription = description
                    .replace(/\s{2,}/g, " ")
                    .trim();
                  if (trimmedDescription.length < 96) {
                    showPreferenceExampleModal();
                    return;
                  }
                  patchDescriptionPreferences(
                    trimmedDescription,
                    savedUser.token,
                  )
                    .then((result) => {
                      if (result.status !== 200) {
                        return Promise.reject(result);
                      }
                      const newMatchPreferences = {
                        ...savedUser.user.matchPreference,
                        ...result.data.preferences,
                      };
                      const user = {
                        ...savedUser.user,
                        matchPreference: newMatchPreferences,
                      };
                      return userContext?.loginHook?.saveUser({
                        ...savedUser,
                        user: user,
                      });
                    })
                    .then(() => history.goBack())
                    .catch((e) => handleError(e, userContext));
                }}
              >
                Save
              </IonButton>
            </div>
            <Modal
              isOpen={modalState.isVisible}
              onDismiss={handleModalDismiss}
              title={modalState.isVisible ? modalState.title : ""}
              dismiss={modalState.isVisible ? modalState.dismiss : undefined}
            >
              {modalState.isVisible ? modalState.content : ""}
            </Modal>
          </main>
        </div>
      </div>
    </IonPage>
  );
}

function patchDescriptionPreferences(
  description: string,
  token: string,
): Promise<HttpResponse> {
  return PATCH({
    url: `${JIBI_BASE_URL}/v1/user/preferences/description`,
    headers: {
      Authorization: token,
    },
    body: {
      description: description,
    },
  });
}
