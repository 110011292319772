import {
  BellAlertIcon,
  EnvelopeIcon,
  LockClosedIcon,
  LockOpenIcon,
  MegaphoneIcon,
  PencilIcon,
  SparklesIcon,
} from "@heroicons/react/24/solid";
import { IonText } from "@ionic/react";
import {
  QueryClient,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { useContext } from "react";
import tinycolor from "tinycolor2";
import { Match } from "../data/Match";
import { MatchesResponse } from "../data/MatchesResponse";
import { patchMatchMeta } from "../datasource/matchmeta-datasource";
import UserContextHook from "../hooks/UserContextHook";
import { JIBI_BASE_URL } from "../util/ApiClient";
import { UserContext } from "../util/BetterDatesApp";
import { formatPrettyTimeRemaining } from "../util/date-ext";
import { handleError, informativeError } from "../util/error";
import Avatar from "./Avatar";

export default function MatchInfo(props: MatchInfoProps) {
  const userContext = useContext(UserContext);
  if (!userContext?.userState.loggedIn) {
    return <></>;
  }
  const savedUser = userContext.userState.savedUser;
  const reported = props.match.meta.reported.reporter === savedUser.user.id;
  return (
    <div
      className={`relative h-[25rem] w-full md:h-[27rem] lg:h-[31rem] xl:h-[33rem]`}
    >
      {reported && (
        <div className="absolute z-10 h-full w-full backdrop-blur-sm">
          <div className="flex h-full w-full flex-col items-center justify-center">
            <IonText className="text-xl font-semibold">
              You have reported this user.
            </IonText>
          </div>
        </div>
      )}
      <div
        className="relative h-full w-full px-4 pt-4"
        style={{
          background: props.match.partyTwo.theme.c0000,
        }}
      >
        <div className="scrollable flex h-full w-full flex-col gap-4 overflow-y-scroll pb-4 no-scrollbar">
          <div className="h-[16rem] w-full bg-gray-500 md:h-[18rem] lg:h-[22rem] xl:h-[24rem]">
            <Avatar
              className="h-full w-full object-cover"
              src={
                props.match.partyTwo.avatar
                  ? `${JIBI_BASE_URL}/files/_pb_users_auth_/${props.match.partyTwo.id}/${props.match.partyTwo.avatar}`
                  : ""
              }
              alt=""
            />
          </div>
          <div className="flex flex-row items-baseline gap-2">
            <IonText
              className="font-mono text-2xl font-medium"
              style={{ color: props.match.partyTwo.theme.t0000 }}
            >
              {props.match.partyTwo.name}
            </IonText>
            <IonText
              className="font-mono"
              style={{ color: props.match.partyTwo.theme.t0001 }}
            >
              {props.match.partyTwo.age}
            </IonText>
          </div>
          <div className="relative h-20 w-full flex-row items-center justify-center">
            {props.match.hasUnreadLetters && (
              <div
                className="absolute z-20 -mt-2.5 h-5 w-5"
                style={{
                  backgroundColor: getProgressiveColor(
                    props.match.partyTwo.theme.c0100,
                    16,
                  ),
                }}
              ></div>
            )}
            <button
              className={`send-letter-button h-12 w-full ${props.match.hasUnreadLetters ? "border-2 border-solid" : "border-[1px] border-dashed"}`}
              style={
                {
                  color: props.match.partyTwo.theme.t0100,
                  "--resting-color": props.match.partyTwo.theme.c0100,
                  "--hover-color": getProgressiveColor(
                    props.match.partyTwo.theme.c0100,
                    8,
                  ),
                  borderColor: getProgressiveColor(
                    props.match.partyTwo.theme.c0100,
                    16,
                  ),
                } as React.CSSProperties
              }
              onClick={props.onClick}
            >
              <div className="flex h-full w-full flex-row items-center justify-between gap-2 px-4 font-semibold">
                <IonText
                  className="font-mono text-sm font-bold underline md:text-base xl:text-lg"
                  style={{ color: props.match.partyTwo.theme.t0100 }}
                >
                  {props.match.hasUnreadLetters
                    ? "You have a new letter!"
                    : "Write a letter"}
                </IonText>
                {props.match.hasUnreadLetters ? (
                  <EnvelopeIcon className="h-4 w-4 lg:h-5 lg:w-5" />
                ) : (
                  <PencilIcon className="h-4 w-4 lg:h-5 lg:w-5" />
                )}
              </div>
            </button>
          </div>
          {!props.previewMode && props.match.meta.locked && (
            <MetaInfoLocked match={props.match} />
          )}
          {!props.previewMode &&
            !props.match.meta.locked &&
            props.match.meta.requestedLock === "" && (
              <MetaInfoUnlocked match={props.match} />
            )}
          {!props.previewMode &&
            !props.match.meta.locked &&
            props.match.meta.requestedLock === savedUser.user.id && (
              <MetaInfoRequestedPartyOne match={props.match} />
            )}
          {!props.previewMode &&
            !props.match.meta.locked &&
            props.match.meta.requestedLock === props.match.partyTwo.id && (
              <MetaInfoRequestedPartyTwo match={props.match} />
            )}
          {!props.previewMode && props.match.partyTwo.hint?.length > 0 && (
            <Hint match={props.match} />
          )}
        </div>
      </div>
    </div>
  );
}

function MetaInfoLocked({ match }: { match: Match }) {
  const userContext = useContext(UserContext);
  if (!userContext?.userState.loggedIn) {
    return <></>;
  }
  const queryClient = useQueryClient();
  const matchMetaMutation = useMatchMetaMutation(queryClient);
  return (
    <div
      className="flex w-full flex-col gap-4"
      style={{
        backgroundColor: getProgressiveColor(match.partyTwo.theme.c0100, 8),
      }}
    >
      <div className="mt-4 flex w-full flex-row items-center gap-2 px-4">
        <LockClosedIcon
          className="h-5 w-5"
          style={{ color: getProgressiveColor(match.partyTwo.theme.t0101, 8) }}
        />
        <IonText
          style={{ color: getProgressiveColor(match.partyTwo.theme.t0101, 8) }}
          className="font-mono text-lg font-bold"
        >
          Locked
        </IonText>
      </div>
      <IonText
        style={{ color: getProgressiveColor(match.partyTwo.theme.t0100, 8) }}
        className="px-4 font-medium"
      >
        Your match is locked and will not expire.
      </IonText>
      <button
        className="bd-button flex w-full flex-row items-center gap-2 border-[1px] border-dashed px-4 py-2"
        disabled={matchMetaMutation.isPending}
        style={
          {
            "--resting-color": getProgressiveColor(
              match.partyTwo.theme.c0100,
              4,
            ),
            "--active-color": getProgressiveColor(
              match.partyTwo.theme.c0100,
              2,
            ),
            borderColor: getProgressiveColor(match.partyTwo.theme.c0100, 16),
          } as React.CSSProperties
        }
        onClick={(e) => {
          e.preventDefault();
          matchMetaMutation.mutate({
            match: { ...match, meta: { ...match.meta, locked: false } },
            userContext,
          });
        }}
      >
        <IonText
          className="font-mono text-lg font-bold underline"
          style={{ color: getProgressiveColor(match.partyTwo.theme.t0100, 8) }}
        >
          Unlock
        </IonText>
      </button>
    </div>
  );
}

function MetaInfoUnlocked({ match }: { match: Match }) {
  const userContext = useContext(UserContext);
  if (!userContext?.userState.loggedIn) {
    return <></>;
  }
  const queryClient = useQueryClient();
  const matchMetaMutation = useMatchMetaMutation(queryClient);
  return (
    <div
      className="flex w-full flex-col gap-4"
      style={{
        backgroundColor: getProgressiveColor(match.partyTwo.theme.c0100, 8),
      }}
    >
      <div className="mt-4 flex w-full flex-row items-center gap-2 px-4">
        <LockOpenIcon
          className="h-5 w-5"
          style={{ color: getProgressiveColor(match.partyTwo.theme.t0101, 8) }}
        />
        <IonText
          style={{ color: getProgressiveColor(match.partyTwo.theme.t0101, 8) }}
          className="font-mono text-lg font-bold"
        >
          Unlocked
        </IonText>
      </div>
      <IonText
        style={{ color: getProgressiveColor(match.partyTwo.theme.t0100, 8) }}
        className="px-4 font-medium"
      >
        Your match is unlocked and will expire in{" "}
        <strong>
          {formatPrettyTimeRemaining(new Date(match.meta.expiresAt))}
        </strong>{" "}
        if not locked by then.
      </IonText>
      <button
        className="bd-button flex w-full flex-row items-center gap-2 border-[1px] border-dashed px-4 py-2"
        disabled={matchMetaMutation.isPending}
        style={
          {
            "--resting-color": getProgressiveColor(
              match.partyTwo.theme.c0100,
              4,
            ),
            "--active-color": getProgressiveColor(
              match.partyTwo.theme.c0100,
              2,
            ),
            borderColor: getProgressiveColor(match.partyTwo.theme.c0100, 16),
          } as React.CSSProperties
        }
        onClick={(e) => {
          e.preventDefault();
          matchMetaMutation.mutate({
            match: { ...match, meta: { ...match.meta, locked: true } },
            userContext,
          });
        }}
      >
        <IonText
          className="font-mono text-lg font-bold underline"
          style={{ color: getProgressiveColor(match.partyTwo.theme.t0100, 8) }}
        >
          Lock
        </IonText>
      </button>
    </div>
  );
}

function MetaInfoRequestedPartyOne({ match }: { match: Match }) {
  const userContext = useContext(UserContext);
  if (!userContext?.userState.loggedIn) {
    return <></>;
  }
  return (
    <div
      className="flex w-full flex-col gap-4"
      style={{
        backgroundColor: getProgressiveColor(match.partyTwo.theme.c0100, 8),
      }}
    >
      <div className="mt-4 flex w-full flex-row items-center gap-2 px-4">
        <MegaphoneIcon
          className="h-5 w-5"
          style={{ color: getProgressiveColor(match.partyTwo.theme.t0101, 8) }}
        />
        <IonText
          style={{ color: getProgressiveColor(match.partyTwo.theme.t0101, 8) }}
          className="font-mono text-lg font-bold"
        >
          Requested
        </IonText>
      </div>
      <IonText
        style={{ color: getProgressiveColor(match.partyTwo.theme.t0100, 8) }}
        className="px-4 font-medium"
      >
        Once {match.partyTwo.name} approves your request, you'll stay locked and
        the match never expires.
      </IonText>
      <IonText
        style={{ color: getProgressiveColor(match.partyTwo.theme.t0100, 8) }}
        className="px-4 pb-4 font-medium"
      >
        Your match will expire in{" "}
        <strong>
          {formatPrettyTimeRemaining(new Date(match.meta.expiresAt))}
        </strong>{" "}
        if not approved.
      </IonText>
    </div>
  );
}

function MetaInfoRequestedPartyTwo({ match }: { match: Match }) {
  const userContext = useContext(UserContext);
  if (!userContext?.userState.loggedIn) {
    return <></>;
  }
  const queryClient = useQueryClient();
  const matchMetaMutation = useMatchMetaMutation(queryClient);
  return (
    <div
      className="flex w-full flex-col gap-4"
      style={{
        backgroundColor: getProgressiveColor(match.partyTwo.theme.c0100, 8),
      }}
    >
      <div className="mt-4 flex w-full flex-row items-center gap-2 px-4">
        <BellAlertIcon
          className="h-5 w-5"
          style={{ color: getProgressiveColor(match.partyTwo.theme.t0101, 8) }}
        />
        <IonText
          style={{ color: getProgressiveColor(match.partyTwo.theme.t0101, 8) }}
          className="font-mono text-lg font-bold"
        >
          Pending
        </IonText>
      </div>
      <IonText
        style={{ color: getProgressiveColor(match.partyTwo.theme.t0100, 8) }}
        className="px-4 font-medium"
      >
        {match.partyTwo.name} has asked your consent for locking your match and
        stay connected indefinitely.
      </IonText>
      <IonText
        style={{ color: getProgressiveColor(match.partyTwo.theme.t0100, 8) }}
        className="px-4 font-medium"
      >
        Your match will expire in{" "}
        <strong>
          {formatPrettyTimeRemaining(new Date(match.meta.expiresAt))}
        </strong>{" "}
        if not approved.
      </IonText>
      <div className="flex w-full flex-row items-center justify-between gap-2">
        <IonText
          className="ps-4 font-mono text-lg font-bold"
          style={{ color: getProgressiveColor(match.partyTwo.theme.t0101, 8) }}
        >
          Approve?
        </IonText>
        <div
          className="flex flex-row items-center border-[1px] border-dashed"
          style={{
            borderColor: getProgressiveColor(match.partyTwo.theme.c0100, 16),
          }}
        >
          <button
            className="bd-button flex w-full min-w-18 flex-row items-center justify-center px-4 py-2"
            disabled={matchMetaMutation.isPending}
            style={
              {
                "--resting-color": getProgressiveColor(
                  match.partyTwo.theme.c0100,
                  4,
                ),
                "--active-color": getProgressiveColor(
                  match.partyTwo.theme.c0100,
                  2,
                ),
              } as React.CSSProperties
            }
            onClick={(e) => {
              e.preventDefault();
              matchMetaMutation.mutate({
                match: { ...match, meta: { ...match.meta, locked: false } },
                userContext,
              });
            }}
          >
            <IonText
              className="font-mono text-lg font-bold underline"
              style={{
                color: getProgressiveColor(match.partyTwo.theme.t0100, 8),
              }}
            >
              No
            </IonText>
          </button>
          <button
            className="bd-button flex w-full min-w-18 flex-row items-center justify-center px-4 py-2"
            disabled={matchMetaMutation.isPending}
            style={
              {
                "--resting-color": getProgressiveColor(
                  match.partyTwo.theme.c0100,
                  4,
                ),
                "--active-color": getProgressiveColor(
                  match.partyTwo.theme.c0100,
                  2,
                ),
              } as React.CSSProperties
            }
            onClick={(e) => {
              e.preventDefault();
              matchMetaMutation.mutate({
                match: { ...match, meta: { ...match.meta, locked: true } },
                userContext,
              });
            }}
          >
            <IonText
              className="font-mono text-lg font-bold underline"
              style={{
                color: getProgressiveColor(match.partyTwo.theme.t0100, 8),
              }}
            >
              Yes
            </IonText>
          </button>
        </div>
      </div>
    </div>
  );
}

function Hint({ match }: { match: Match }) {
  return (
    <div
      className="flex w-full flex-col gap-4 p-4"
      style={{
        backgroundColor: getProgressiveColor(match.partyTwo.theme.c0100, 8),
      }}
    >
      <div className="flex w-full flex-row items-center gap-2">
        <SparklesIcon
          className="h-5 w-5"
          style={{ color: getProgressiveColor(match.partyTwo.theme.t0101, 8) }}
        />
        <IonText
          style={{ color: getProgressiveColor(match.partyTwo.theme.t0101, 8) }}
          className="font-mono text-lg font-bold"
        >
          AI Hint
        </IonText>
      </div>
      <IonText
        style={{ color: getProgressiveColor(match.partyTwo.theme.t0100, 8) }}
        className="font-medium"
      >
        {match.partyTwo.hint}
      </IonText>
    </div>
  );
}

function useMatchMetaMutation(queryClient: QueryClient) {
  return useMutation({
    mutationFn: (props: { match: Match; userContext: UserContextHook }) => {
      if (!props.userContext.userState.loggedIn) {
        return Promise.reject(informativeError("User isn't logged in"));
      }
      const savedUser = props.userContext.userState.savedUser;
      return patchMatchMeta(props.match.id, props.match.meta, savedUser);
    },
    onSuccess: (data, variables) => {
      const updatedMatch = {
        ...variables.match,
        meta: data,
      };
      const updatedMatches =
        queryClient
          .getQueryData<MatchesResponse>([
            variables.userContext.queryKeys.matches,
          ])
          ?.matches?.map((value) => {
            return value.id === updatedMatch.id ? updatedMatch : value;
          }) || [];
      queryClient.setQueryData<MatchesResponse>(
        [variables.userContext.queryKeys.matches],
        { matches: updatedMatches },
      );
    },
    onError: (error, variables) => {
      handleError(error, variables.userContext);
    },
  });
}

function getProgressiveColor(
  normalColor: string,
  level: 2 | 4 | 8 | 16,
): string {
  const color = tinycolor(normalColor);
  if (color.isLight()) {
    return color.darken(level).toString();
  } else {
    return color.lighten(level).toString();
  }
}

export type ProfilePictureStateType = "innocent" | "error";

export interface ProfilePictureState {
  state: ProfilePictureStateType;
  transform: (to: () => ProfilePictureStateType) => void;
}

export interface MatchInfoState {
  profilePictureState: ProfilePictureState;
}

interface MatchInfoProps {
  state: MatchInfoState;
  match: Match;
  previewMode?: boolean;
  onClick: () => void;
}
