import { MatchMeta } from "../data/Match";
import { SavedUser } from "../hooks/LoginHook";
import { JIBI_BASE_URL, PATCH } from "../util/ApiClient";

export async function patchMatchMeta(
  matchId: string,
  matchMeta: MatchMeta,
  savedUser: SavedUser,
): Promise<MatchMeta> {
  return PATCH({
    url: `${JIBI_BASE_URL}/v1/match_meta/${matchId}`,
    headers: {
      Authorization: savedUser.token,
    },
    body: {
      locked: matchMeta.locked,
    },
  }).then((result) => {
    if (result.status !== 200) {
      return Promise.reject(result);
    }
    return result.data.meta as MatchMeta;
  });
}
