import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import {
  EllipsisHorizontalCircleIcon,
  EnvelopeIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import {
  IonActionSheet,
  IonAvatar,
  IonButton,
  IonChip,
  IonContent,
  IonLabel,
  IonPage,
  IonRippleEffect,
  IonText,
} from "@ionic/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Avatar from "../../components/Avatar";
import ScrollableContent from "../../components/ScrollableContent";
import Letter from "../../data/Letter";
import LettersResponse from "../../data/LettersResponse";
import { Match } from "../../data/Match";
import { MatchesResponse } from "../../data/MatchesResponse";
import { getLetters, patchLetter } from "../../datasource/letter-datasource";
import UserContextHook from "../../hooks/UserContextHook";
import { ERROR_DEFAULT } from "../../i18n/static";
import "../../theme/variables.css";
import { JIBI_BASE_URL } from "../../util/ApiClient";
import { getAvatarUrl } from "../../util/AvatarExt";
import { UserContext } from "../../util/BetterDatesApp";
import { formatDateToHumanReadable } from "../../util/date-ext";
import { handleError } from "../../util/error";
import Modal, { ModalStateType } from "../../components/modal/Modal";
import ReportModal from "../../components/modal/ReportModal";

export default function ListOfLetters() {
  const params = useParams<ListOfLettersProps>();
  const history = useHistory();
  const userContext = useContext(UserContext);
  const [state, setState] = useState<ListOfLettersStateType>({
    type: "loading",
  });
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);
  const [modalState, setModalState] = useState<ModalStateType>({
    isVisible: false,
  });
  const queryClient = useQueryClient();
  if (!userContext?.userState.loggedIn) {
    return <></>;
  }
  const savedUser = userContext.userState.savedUser;

  const lettersQuery = useQuery({
    queryKey: [userContext.queryKeys.letters, params.matchId],
    queryFn: () => {
      return getLetters(params.matchId, savedUser);
    },
    retry: false,
    refetchOnMount: false,
    retryOnMount: true,
  });

  useEffect(() => {
    if (lettersQuery.isLoading) {
      setState({ type: "loading" });
    }
  }, [lettersQuery.isLoading]);

  useEffect(() => {
    if (lettersQuery.isError) {
      setState({
        type: "error",
        error: lettersQuery.error,
        message: ERROR_DEFAULT,
      });
    }
  }, [lettersQuery.isError]);

  useEffect(() => {
    const data = lettersQuery.data;
    if (!lettersQuery.isSuccess || !data) {
      return;
    }
    if (data.letters.length < 1) {
      history.replace(`/write/${params.matchId}/${params.partyTwoId}`);
      return;
    }
    setState({
      type: "loaded",
      letters: data.letters,
      match: data.match,
    });
  }, [lettersQuery.data]);

  return (
    <IonPage className="flex items-center justify-center bg-gray-50">
      <div className="h-full w-full max-w-md">
        <div className="safe-scroller flex h-full w-full flex-col justify-between">
          <header className="flex h-14 flex-row items-center justify-between px-3">
            <IonChip
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
            >
              <ChevronLeftIcon className="me-1 h-5 w-5 text-gray-500" />
              <IonLabel>Back</IonLabel>
            </IonChip>
            <div
              className="ion-activatable relative flex h-10 w-10 items-center justify-center overflow-hidden rounded-full"
              onClick={(e) => {
                e.preventDefault();
                setIsActionSheetOpen(true);
              }}
            >
              <EllipsisHorizontalCircleIcon className="h-6.5 w-6.5 stroke-gray-900" />
              <IonRippleEffect />
            </div>
          </header>
          <main className="flex h-full w-full items-center justify-center">
            {state.type === "loading" && <EmptyStateLoading />}
            {state.type === "error" && (
              <EmptyStateError
                errorMessage={state.message}
                onRetry={() => {
                  queryClient.invalidateQueries({
                    queryKey: [userContext.queryKeys.letters, params.matchId],
                    refetchType: "all",
                  });
                }}
              />
            )}
            {state.type === "loaded" && (
              <>
                <Loaded letters={state.letters} match={state.match} />
                <IonActionSheet
                  isOpen={isActionSheetOpen}
                  buttons={[
                    {
                      text: "Report your match",
                      role: "destructive",
                      handler: () => {
                        setModalState({
                          isVisible: true,
                          content: (
                            <ReportModal
                              setModalState={setModalState}
                              match={state.match}
                            />
                          ),
                          title: "Report your match",
                          dismiss: "Cancel",
                        });
                      },
                    },
                    {
                      text: "Cancel",
                      role: "cancel",
                      data: {
                        action: "cancel",
                      },
                    },
                  ]}
                  onDidDismiss={() => setIsActionSheetOpen(false)}
                />
              </>
            )}
            <Modal
              isOpen={modalState.isVisible}
              onDismiss={() => {
                setModalState({ isVisible: false });
              }}
              title={modalState.isVisible ? modalState.title : ""}
            >
              {modalState.isVisible ? modalState.content : ""}
            </Modal>
          </main>
        </div>
      </div>
    </IonPage>
  );
}

function EmptyStateLoading() {
  return (
    <div>
      <IonText>Loading...</IonText>
    </div>
  );
}

function EmptyStateError(props: { errorMessage: string; onRetry: () => void }) {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <IonText>{props.errorMessage}</IonText>
      <IonButton
        color="dark"
        onClick={(e) => {
          e.preventDefault();
          props.onRetry();
        }}
      >
        Retry
      </IonButton>
    </div>
  );
}

function Loaded(props: { letters: Letter[]; match: Match }) {
  const history = useHistory();
  return (
    <div className="flex h-full w-full flex-col px-3">
      <IonContent className="h-full w-full" scrollY={false}>
        <ScrollableContent>
          <div className="flex h-full w-full flex-col">
            <div
              className="my-4 flex w-full flex-row items-center gap-2 rounded-sm p-2 active:bg-gray-200"
              onClick={(e) => {
                e.preventDefault();
                history.push(
                  `/write/${props.match.id}/${props.match.partyTwo.id}`,
                );
              }}
            >
              <PencilIcon className="h-5 w-5 text-black" />
              <IonText className="font-mono font-bold underline">Write</IonText>
            </div>
            {props.letters.map((value, index) => {
              return (
                <LetterItem
                  key={value.id}
                  letter={value}
                  match={props.match}
                  isLastItem={index === props.letters.length - 1}
                />
              );
            })}
          </div>
        </ScrollableContent>
      </IonContent>
    </div>
  );
}

function LetterItem({
  letter,
  match,
  isLastItem,
}: {
  letter: Letter;
  match: Match;
  isLastItem: boolean;
}) {
  const userContext = useContext(UserContext);
  if (!userContext?.userState.loggedIn) {
    return <></>;
  }
  const savedUser = userContext.userState.savedUser;
  const markLetterAsRead = useMarkLetterAsRead(userContext);
  return (
    <div className="flex w-full flex-row">
      <div className="flex h-full w-10 flex-col items-center">
        <div className="flex h-4 w-4 flex-row items-center">
          <IonAvatar className="h-4 w-4">
            {letter.from === savedUser.user.id ? (
              <Avatar
                src={getAvatarUrl(savedUser.user)}
                alt={""}
                rounded={true}
              />
            ) : (
              <Avatar
                src={`${JIBI_BASE_URL}/files/_pb_users_auth_/${match.partyTwo.id}/${match.partyTwo.avatar}`}
                alt={""}
                rounded={true}
              />
            )}
          </IonAvatar>
        </div>
        {!isLastItem && (
          <div className="h-full border-s-[1px] border-dashed border-gray-300"></div>
        )}
      </div>
      <div className="-mt-0.5 flex w-full flex-col gap-2 pb-5 transition-all duration-300 ease-in-out">
        <div className="flex w-full flex-row gap-2 text-sm">
          <IonText className="text-gray-700">Letter #{letter.num}</IonText>
          <IonText className="text-gray-400">&#8226;</IonText>
          <IonText className="text-gray-400">
            {formatDateToHumanReadable(new Date(letter.sendDate))}
          </IonText>
        </div>
        {letter.read || letter.from === savedUser.user.id ? (
          <IonText
            className="whitespace-pre-wrap p-2 text-start font-mono font-medium text-black"
            dir="auto"
          >
            {letter.letter}
          </IonText>
        ) : (
          <div
            className="flex w-full flex-row items-center gap-2 rounded-sm p-2 active:bg-gray-200"
            onClick={(e) => {
              e.preventDefault();
              markLetterAsRead?.mutate({ match: match, letter: letter });
            }}
          >
            <EnvelopeIcon className="h-5 w-5 text-black" />
            <IonText className="font-mono font-bold underline">Open</IonText>
          </div>
        )}
      </div>
    </div>
  );
}

function useMarkLetterAsRead(userContext: UserContextHook) {
  if (!userContext.userState.loggedIn) {
    return undefined;
  }
  const savedUser = userContext.userState.savedUser;
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (variables: { match: Match; letter: Letter }) => {
      const response = await patchLetter(savedUser, {
        ...variables.letter,
        read: true,
      });

      if (response.status !== 200) {
        throw new Error("Failed to mark letter as read");
      }

      return {
        updatedLetter: {
          ...variables.letter,
          ...response.data.letter,
        } as Letter,
        matchId: variables.match.id,
      };
    },

    onSuccess: async (data, variables) => {
      let hasUnreadLetters = false;
      await queryClient.setQueryData(
        [userContext.queryKeys.letters, variables.match.id],
        (cachedLettersResponse: LettersResponse | undefined) => {
          const cachedLetters = cachedLettersResponse?.letters;
          if (!cachedLetters) return undefined;

          return {
            letters: cachedLetters.map((oldLetter) => {
              const isItTheUpdatedLetter =
                oldLetter.id === data.updatedLetter.id;
              if (
                !isItTheUpdatedLetter &&
                oldLetter.to === savedUser.user.id &&
                !oldLetter.read
              ) {
                hasUnreadLetters = true;
              }
              return isItTheUpdatedLetter ? data.updatedLetter : oldLetter;
            }),
            match: cachedLettersResponse.match,
          };
        },
      );

      if (!hasUnreadLetters) {
        await queryClient.setQueryData(
          [userContext.queryKeys.matches],
          (cachedMatchesReponse: MatchesResponse | undefined) => {
            const cachedMatches = cachedMatchesReponse?.matches;
            if (!cachedMatches) return [];

            return {
              matches: cachedMatches.map((oldMatch) =>
                oldMatch.id === variables.match.id
                  ? { ...oldMatch, hasUnreadLetters: false }
                  : oldMatch,
              ),
            };
          },
        );
      }
    },

    onError: (error) => {
      handleError(error, userContext);
    },
  });
}

interface ListOfLettersState {
  type: string;
}

interface ListOfLettersStateLoading extends ListOfLettersState {
  type: "loading";
}

interface ListOfLettersStateLoaded extends ListOfLettersState {
  type: "loaded";
  letters: Letter[];
  match: Match;
}

interface ListOfLettersStateError extends ListOfLettersState {
  type: "error";
  error: Error;
  message: string;
}

type ListOfLettersStateType =
  | ListOfLettersStateLoaded
  | ListOfLettersStateError
  | ListOfLettersStateLoading;

export type ListOfLettersProps = {
  partyTwoId: string;
  matchId: string;
};
