import {
  CheckCircleIcon,
  ChevronLeftIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/20/solid";
import {
  IonChip,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonPage,
  IonText,
} from "@ionic/react";
import { useContext } from "react";
import { useHistory } from "react-router";
import { Location } from "../../data/location";
import { UserContext } from "../../util/BetterDatesApp";
import {
  isGenderValid,
  isHeightValid,
  isLocationValid,
  isMatchPreferencesDescriptionPresent,
  isMatchPreferencesGenderPresent,
  isReligionValid,
  isSexualOrientationValid,
} from "../../validation/ProfileValidation";

export default function PreferencesFlow() {
  const history = useHistory();
  const userContext = useContext(UserContext);
  if (!userContext?.userState.loggedIn) {
    return;
  }
  const savedUser = userContext.userState.savedUser;
  return (
    <IonPage className="flex items-center justify-center bg-gray-50">
      <div className="h-full w-full max-w-md">
        <div className="safe-scroller flex h-full w-full flex-col justify-between">
          <header className="flex h-14 flex-row items-center justify-between px-3">
            <IonChip
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
            >
              <ChevronLeftIcon className="me-1 h-5 w-5 text-gray-500" />
              <IonLabel>Back</IonLabel>
            </IonChip>
          </header>
          <main className="flex h-full w-full flex-col px-3">
            <IonContent className="h-full w-full" scrollY={true}>
              <div className="mt-4 flex w-full flex-col gap-1 px-3">
                <IonText className="text-2xl font-bold">Preferences</IonText>
                <IonText className="text-sm text-gray-400">
                  We don&apos;t show these information on your profile to your
                  matches. We use these information to understand about you, so
                  we can find your perfect match.
                </IonText>
              </div>
              <IonList className="mt-4" inset={true}>
                <IonItem
                  button
                  detail
                  onClick={(e) => {
                    e.preventDefault();
                    history.push("/change-location");
                  }}
                >
                  <CheckOrErrorIcon
                    isValid={isLocationValid(savedUser.user).valid}
                  />
                  <IonLabel>Location</IonLabel>
                  <IonNote slot="end">
                    {getHumanReadableLocation(savedUser.user.location)}
                  </IonNote>
                </IonItem>
                <IonItem
                  button
                  detail
                  onClick={(e) => {
                    e.preventDefault();
                    history.push("/gender");
                  }}
                >
                  <CheckOrErrorIcon
                    isValid={
                      isGenderValid(savedUser.user).valid &&
                      isMatchPreferencesGenderPresent(savedUser.user).valid
                    }
                  />
                  <IonLabel>Gender</IonLabel>
                </IonItem>
                <IonItem
                  button
                  detail
                  onClick={(e) => {
                    e.preventDefault();
                    history.push("/sexual-orientation");
                  }}
                >
                  <CheckOrErrorIcon
                    isValid={isSexualOrientationValid(savedUser.user).valid}
                  />
                  <IonLabel>Sexual Orientation</IonLabel>
                </IonItem>
                <IonItem
                  button
                  detail
                  onClick={(e) => {
                    e.preventDefault();
                    history.push("/preferences/description");
                  }}
                >
                  <CheckOrErrorIcon
                    isValid={
                      isMatchPreferencesDescriptionPresent(savedUser.user).valid
                    }
                  />
                  <IonLabel>What are you looking for</IonLabel>
                </IonItem>
                <IonItem
                  button
                  detail
                  onClick={(e) => {
                    e.preventDefault();
                    history.push("/height");
                  }}
                >
                  <CheckOrWarningIcon
                    isValid={isHeightValid(savedUser.user).valid}
                  />
                  <IonLabel>Height</IonLabel>
                  <IonNote slot="end">
                    {savedUser.user.height ? `${savedUser.user.height} cm` : ""}
                  </IonNote>
                </IonItem>
                <IonItem
                  button
                  detail
                  onClick={(e) => {
                    e.preventDefault();
                    history.push("/religion");
                  }}
                >
                  <CheckOrWarningIcon
                    isValid={isReligionValid(savedUser.user).valid}
                  />
                  <IonLabel>Religion</IonLabel>
                </IonItem>
              </IonList>
            </IonContent>
          </main>
        </div>
      </div>
    </IonPage>
  );
}

function getHumanReadableLocation(location?: Location): string {
  if (!location || !location.city || !location.country) {
    return "";
  }
  if (location.city.length > 0 && location.country.length > 0) {
    return `${location.city}, ${location.country}`;
  }
  return `${location.city}${location.country}`;
}

function CheckOrErrorIcon(props: { isValid: boolean }) {
  return (
    <div slot="start">
      {props.isValid ? (
        <>
          <CheckCircleIcon
            className="h-5 w-5 text-green-500"
            aria-hidden="true"
          />
        </>
      ) : (
        <ExclamationCircleIcon
          className="h-5 w-5 text-red-500"
          aria-hidden="true"
        />
      )}
    </div>
  );
}

function CheckOrWarningIcon(props: { isValid: boolean }) {
  return (
    <div slot="start">
      {props.isValid ? (
        <>
          <CheckCircleIcon
            className="h-5 w-5 text-green-500"
            aria-hidden="true"
          />
        </>
      ) : (
        <ExclamationCircleIcon
          className="h-5 w-5 text-yellow-500"
          aria-hidden="true"
        />
      )}
    </div>
  );
}
