import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { IonChip, IonContent, IonLabel, IonPage, IonText } from "@ionic/react";
import { useHistory } from "react-router";
import ScrollableContent from "../../components/ScrollableContent";
import { Browser } from "@capacitor/browser";
import { handleError } from "../../util/error";
import { useContext } from "react";
import { UserContext } from "../../util/BetterDatesApp";

export default function Philosophy() {
  const userContext = useContext(UserContext);
  const history = useHistory();
  if (!userContext) {
    return <></>;
  }
  return (
    <IonPage className="flex items-center justify-center bg-gray-50">
      <div className="h-full w-full max-w-md">
        <div className="safe-scroller flex h-full w-full flex-col justify-between">
          <header className="flex h-14 flex-row items-center justify-between px-3">
            <IonChip
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
            >
              <ChevronLeftIcon className="me-1 h-5 w-5 text-gray-500" />
              <IonLabel>Back</IonLabel>
            </IonChip>
          </header>
          <main className="flex h-full w-full flex-col">
            <IonContent className="h-full" scrollY={false}>
              <ScrollableContent>
                <div className="flex w-full flex-col gap-5 p-4">
                  <IonText className="font-serif text-xl font-bold text-gray-400">
                    “
                  </IonText>
                  <IonText className="font-mono font-medium">
                    Number one, the sun and the orbit. Relationships are like a
                    solar system. They are the sun, steady and radiant at the
                    center, while you, the planet, orbit around them. As you
                    move, you see different parts of who they are, some bright,
                    some shadowed. They’re always changing, yet their core
                    remains the same. It’s a reminder that true connections come
                    from understanding the whole picture and not just parts that
                    shine the brightest.
                  </IonText>
                  <IonText className="font-mono font-medium">
                    Number two, ambivalence and clarity. Ambivalence in a
                    relationship can feel like an eclipse, blocking the light
                    and leaving you in confusion. But the truth is simple. If
                    someone truly values you, there wouldn’t be any doubts. This
                    clarity can save you from overthinking or idolizing someone,
                    which only blinds you to red flags. Being honest with
                    yourself keeps you grounded and helps you see things just as
                    they are.
                  </IonText>
                  <IonText className="font-mono font-medium">
                    Number three, letting relationships evolve. Healthy
                    relationships take time to grow. Rushing in can lead to
                    burnout just as fast as it started. Starting as friends
                    allows you to truly see if you’re compatible. What looks
                    perfect on the outside might not match what’s on the inside.
                    While appearances fade, character and connection endure.
                    Avoid putting in all the effort if it feels one-sided. It
                    only creates imbalance. Let things evolve naturally without
                    forcing them, and at the same time, don’t let fear of
                    rejection stop you from expressing your feelings. Balance is
                    key. Give space for the relationship to grow, but also be
                    clear about where you stand.
                  </IonText>
                  <IonText className="font-mono font-medium">
                    Number four, emotional awareness and boundaries. When
                    emotions run high, pause before reacting. I always take a
                    three breath before I proceed. A moment of reflection can
                    stop a bad situation from spiraling. Ask yourself if your
                    response will help or hurt. Emotional regulation builds a
                    strong foundation, and a calm approach can make a big
                    difference. Relationships also require you to receive, not
                    just give. Being open to what someone offers without
                    expecting too much can lead to deeper and more genuine
                    connections. However, it’s important to set boundaries. Make
                    sure what you’re receiving aligns with your values because
                    not everything offered is worth holding onto.
                  </IonText>
                  <IonText className="font-mono font-medium">
                    Last is number five, self-awareness and growth. The flaws
                    you notice in others often reflect struggles with yourself.
                    Self-awareness can help you break out of unhealthy patterns
                    and make room for healthier and more authentic
                    relationships. It’s easy to get caught up imagining the
                    future, like marriage and having kids. But expectations
                    don’t always align with reality. Thinking too far ahead can
                    only create unnecessary pressure and disappointment. So
                    instead, focus on the present. Like the solar system,
                    relationships always are in motion. Growth takes time, and
                    appreciating the process is just as important as the
                    outcome. And to end it, relationships, like the cosmos,
                    remind us that everything worth having evolves over time,
                    and they are a journey constantly shifting and revealing new
                    layers. Just as we marvel at the universe, we should
                    approach relationships with patience, curiosity, and a focus
                    on the beauty of the process. Planets set boundaries too!
                  </IonText>
                  <IonText className="text-end font-serif text-xl font-bold text-gray-400">
                    ”
                  </IonText>
                  <div
                    className="flex w-full flex-row items-center gap-2 rounded-sm p-2 active:bg-gray-200"
                    onClick={(e) => {
                      e.preventDefault();
                      Browser.open({
                        url: "https://www.youtube.com/watch?v=j8_KOoaDMho",
                      }).catch((e) => {
                        handleError(e, userContext);
                      });
                    }}
                  >
                    <IonText className="font-mono text-lg font-bold underline">
                      – yan
                    </IonText>
                  </div>
                </div>
              </ScrollableContent>
            </IonContent>
          </main>
        </div>
      </div>
    </IonPage>
  );
}
