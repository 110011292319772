import { HttpResponse } from "@capacitor/core";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import {
  IonButton,
  IonChip,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRippleEffect,
  IonText,
} from "@ionic/react";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { useHistory } from "react-router";
import CountriesDropDown from "../../components/modal/CountriesModal";
import Modal, { ModalStateType } from "../../components/modal/Modal";
import OTPInput from "../../components/OTPInput";
import CountriesJSON from "../../countries.json";
import Country from "../../data/Country";
import User from "../../data/User";
import { SavedUser } from "../../hooks/LoginHook";
import UserContextHook from "../../hooks/UserContextHook";
import { JIBI_BASE_URL, PATCH, POST } from "../../util/ApiClient";
import { UserContext } from "../../util/BetterDatesApp";
import { handleError } from "../../util/error";
import { defaultToastState } from "../../util/IonicExt";
import { isProfileComplete } from "../../validation/ProfileValidation";

export default function PhoneLogin() {
  const [loginState, setLoginState] = useState<LoginStateType>({
    hasRequestedVerification: false,
  });

  return (
    <IonPage className="flex items-center justify-center bg-gray-50">
      <div className="h-full w-full max-w-md">
        <div className="safe-scroller flex h-full w-full flex-col justify-between">
          {!loginState.hasRequestedVerification && (
            <PhoneNumberInput setLoginState={setLoginState} />
          )}
          {loginState.hasRequestedVerification && (
            <PhoneNumberVerification
              phoneNumber={loginState.phoneNumber}
              setLoginState={setLoginState}
            />
          )}
        </div>
      </div>
    </IonPage>
  );
}

function PhoneNumberInput(props: {
  setLoginState: Dispatch<SetStateAction<LoginStateType>>;
}) {
  const userContext = useContext(UserContext);
  if (!userContext) {
    return <></>;
  }
  const history = useHistory();
  const [phoneNumber, setPhoneNumber] = useState("");

  const [modalState, setModalState] = useState<ModalStateType>({
    isVisible: false,
  });

  const [selectedCountry, setSelectedCountry] = useState<Country>(
    CountriesJSON.countries.find((value) => {
      return value.name === "United States(US)";
    }) || CountriesJSON.countries[0],
  );
  const [inProgress, setInProgress] = useState(false);

  const showCountriesSelectionModal = () => {
    setModalState({
      isVisible: true,
      content: (
        <CountriesDropDown
          setModalState={setModalState}
          onSelectCountry={(country) => {
            setSelectedCountry(country);
          }}
        />
      ),
      title: "Select a Country",
    });
  };

  const handleModalDismiss = () => {
    setModalState({
      isVisible: false,
    });
  };

  return (
    <>
      <header className="flex h-56 flex-row items-center px-8">
        <IonText className="text-2xl font-bold">Hi!</IonText>
      </header>
      <main className="flex h-full w-full flex-col px-8 pb-4">
        <div className="flex w-full flex-col gap-4">
          <IonText>Please enter your phone number</IonText>
          <div className="relative flex flex-row items-center gap-2">
            <div
              className="ion-activatable relative min-w-fit overflow-hidden rounded-lg bg-gray-100 p-2"
              onClick={(e) => {
                e.preventDefault();
                showCountriesSelectionModal();
              }}
            >
              <IonText className="text-xl">
                {selectedCountry.flag} +{selectedCountry.code}
              </IonText>
              <IonRippleEffect />
            </div>
            <IonInput
              placeholder="123456"
              type="tel"
              color="dark"
              autofocus={true}
              className="force-dark text-xl"
              value={phoneNumber}
              onIonInput={(e) => {
                const newInput = e.detail.value as string;
                setPhoneNumber(newInput);
              }}
            />
          </div>
          {!import.meta.env.PROD && (
            <IonItem
              className="mt-4"
              button={true}
              lines={"none"}
              color="light"
              detail={true}
              onClick={() => {
                history.replace("/email-login");
              }}
            >
              <IonLabel>Login with email instead</IonLabel>
            </IonItem>
          )}
        </div>
        <div className="flex w-full flex-row justify-end">
          <IonButton
            className={"mt-8 h-9 w-20"}
            shape={"round"}
            color={"dark"}
            size={"small"}
            disabled={phoneNumber.length === 0 || inProgress}
            onClick={(e) => {
              e.preventDefault();

              const completePhoneNumber = `+${selectedCountry.code}${phoneNumber}`;
              const valid = /^\+[1-9]\d{1,14}$/.test(completePhoneNumber);
              if (!valid) {
                userContext?.toastHook.toast({
                  ...defaultToastState(),
                  message: "Phone number is not valid!",
                  isOpen: true,
                });

                return;
              }
              setInProgress(true);
              requestSmsCode(completePhoneNumber)
                .then((response) => {
                  if (response.status !== 200) {
                    return Promise.reject(response);
                  }
                  props.setLoginState({
                    hasRequestedVerification: true,
                    phoneNumber: completePhoneNumber,
                  });
                })
                .catch((e) => handleError(e, userContext))
                .finally(() => {
                  setInProgress(false);
                });
            }}
          >
            Verify
          </IonButton>
        </div>
        <Modal
          isOpen={modalState.isVisible}
          onDismiss={handleModalDismiss}
          title={modalState.isVisible ? modalState.title : ""}
        >
          {modalState.isVisible && modalState.content}
        </Modal>
      </main>
    </>
  );
}

function PhoneNumberVerification(props: {
  phoneNumber: string;
  setLoginState: Dispatch<SetStateAction<LoginStateType>>;
}) {
  const userContext = useContext(UserContext);
  if (!userContext) {
    return <></>;
  }
  const history = useHistory();
  const [verificationCode, setVerificationCode] = useState("");
  const [inProgress, setInProgress] = useState(false);

  return (
    <>
      <header className="flex flex-col gap-8 px-8 py-8">
        <div>
          <IonChip
            onClick={(e) => {
              e.preventDefault();
              props.setLoginState({ hasRequestedVerification: false });
            }}
          >
            <ChevronLeftIcon className="me-1 h-5 w-5 text-gray-500" />
            <IonLabel>Back</IonLabel>
          </IonChip>
        </div>
        <IonText className="text-2xl font-bold">Verification code</IonText>
      </header>
      <main className="flex h-full w-full flex-col px-8 pb-4">
        <div className="flex w-full flex-col gap-8">
          <IonText>
            Please enter the code we&apos;ve sent to: <b>{props.phoneNumber}</b>
          </IonText>
          <div className="flex flex-row items-center justify-center">
            <OTPInput value={verificationCode} onChange={setVerificationCode} />
          </div>
          <div className="flex flex-row justify-end">
            <IonButton
              className={"h-9 w-20"}
              shape={"round"}
              color={"dark"}
              disabled={inProgress}
              size={"small"}
              fill={"clear"}
              onClick={(e) => {
                e.preventDefault();
                setInProgress(true);
                requestSmsCode(props.phoneNumber)
                  .then((response) => {
                    if (response.status !== 200) {
                      return Promise.reject(response);
                    }
                    userContext?.toastHook.toast({
                      ...defaultToastState(),
                      message: "Requested a new code",
                      isOpen: true,
                    });
                  })
                  .catch((e) => handleError(e, userContext))
                  .finally(() => {
                    setInProgress(false);
                  });
              }}
            >
              Resend
            </IonButton>
            <IonButton
              className={"h-9 w-20"}
              shape={"round"}
              color={"dark"}
              size={"small"}
              disabled={verificationCode.length < 6 || inProgress}
              onClick={(e) => {
                e.preventDefault();
                setInProgress(true);
                verifySmsCode(props.phoneNumber, verificationCode)
                  .then((result) => {
                    if (result.status !== 200) {
                      return Promise.reject(result);
                    }
                    return saveLoginInfo(result.data, userContext);
                  })
                  .then((savedUser) => {
                    if (savedUser.isProfileComplete) {
                      history.replace("/home");
                    } else {
                      history.replace("/complete-profile");
                    }
                  })
                  .catch((e) => handleError(e, userContext))
                  .finally(() => {
                    setInProgress(false);
                  });
              }}
            >
              Verify
            </IonButton>
          </div>
        </div>
      </main>
    </>
  );
}

function requestSmsCode(phoneNumber: string): Promise<HttpResponse> {
  return POST({
    url: `${JIBI_BASE_URL}/v1/user/verification/sms`,
    body: {
      phoneNumber: phoneNumber,
    },
  });
}

function verifySmsCode(
  phoneNumber: string,
  code: string,
): Promise<HttpResponse> {
  return PATCH({
    url: `${JIBI_BASE_URL}/v1/user/verification/sms`,
    body: {
      phoneNumber: phoneNumber,
      code: code,
    },
  });
}

function saveLoginInfo(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response: any,
  userContext: UserContextHook | undefined,
): Promise<SavedUser> {
  const rawUserResponse = response.user;
  const birthday = rawUserResponse.birthday
    ? rawUserResponse.birthday === ""
      ? undefined
      : new Date(rawUserResponse.birthday)
    : undefined;
  const user = {
    ...rawUserResponse,
    birthday: birthday,
  } as User;
  return (
    userContext?.loginHook?.saveUser({
      token: response.token as string,
      user: user,
      isProfileComplete: isProfileComplete(user),
    }) || Promise.reject()
  );
}

interface LoginState {
  hasRequestedVerification: boolean;
}

interface LoginStateInput extends LoginState {
  hasRequestedVerification: false;
}

interface LoginStateVerify extends LoginState {
  hasRequestedVerification: true;
  phoneNumber: string;
}

type LoginStateType = LoginStateInput | LoginStateVerify;
