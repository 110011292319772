import {
  IonAvatar,
  IonChip,
  IonImg,
  IonLabel,
  IonRouterLink,
} from "@ionic/react";
import { useContext } from "react";
import { getAvatarUrl } from "../util/AvatarExt";
import { UserContext } from "../util/BetterDatesApp";
import Avatar from "./Avatar";
import { useHistory } from "react-router";

const Header = () => {
  const userContext = useContext(UserContext);
  const history = useHistory();
  return (
    <header className="flex h-14 flex-row items-center justify-center px-3">
      <div className="flex w-full flex-row">
        <IonImg
          src="/assets/betterdates_abstract.png"
          alt="BetterDates Logo"
          className="h-9 w-9"
          draggable={false}
          onClick={(e) => {
            e.preventDefault();
            history.push("/home/philosophy");
          }}
        />
      </div>
      <div className="flex flex-none justify-end">
        <IonRouterLink routerLink="/edit-profile" routerDirection="forward">
          <IonChip>
            <IonAvatar>
              {userContext?.userState?.loggedIn && (
                <Avatar
                  src={getAvatarUrl(userContext.userState.savedUser.user)}
                  alt="Profile Picture"
                  rounded={true}
                />
              )}
            </IonAvatar>
            <IonLabel>Profile</IonLabel>
          </IonChip>
        </IonRouterLink>
      </div>
    </header>
  );
};

export default Header;
