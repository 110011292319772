import { HttpResponse } from "@capacitor/core";
import { IonButton, IonText, IonTextarea } from "@ionic/react";
import React, { useContext, useState } from "react";
import { ERROR_DEFAULT } from "../../i18n/static";
import { JIBI_BASE_URL, POST } from "../../util/ApiClient";
import { UserContext } from "../../util/BetterDatesApp";
import { defaultToastState } from "../../util/IonicExt";
import { ModalStateType } from "./Modal";
import { SavedUser } from "../../hooks/LoginHook";
import { handleError } from "../../util/error";

export default function FeedbackModal({ setModalState }: FeedbackModalProps) {
  const userContext = useContext(UserContext);
  const [feedBack, setFeedBack] = useState("");
  const [inProgress, setInProgress] = useState(false);

  if (!userContext?.userState?.loggedIn) {
    return <></>;
  }
  const savedUser = userContext?.userState.savedUser;

  return (
    <main className="flex h-full w-full flex-col justify-between px-4">
      <div className="flex h-full w-full flex-col gap-4">
        <IonText className="pt-4">
          Your message will be read by us and is of tremendous value. Thank you.
        </IonText>
        <div className="flex w-full">
          <div className="h-full w-full rounded-lg bg-gray-200 px-2 pb-4">
            <IonTextarea
              ref={(ref) => {
                if (ref) {
                  ref.getInputElement().then((element) => {
                    element.dir = "auto";
                    element.style.height = "150px";
                  });
                }
              }}
              placeholder="Please type here..."
              autofocus={true}
              color={"dark"}
              onIonInput={(e) => {
                setFeedBack(e.detail.value || "");
              }}
              className="h-full w-full overflow-y-scroll no-scrollbar"
            />
          </div>
        </div>
      </div>
      <div className="flex h-20 w-full flex-row justify-end">
        <IonButton
          className="h-9 w-20"
          shape="round"
          disabled={feedBack.length < 8 || inProgress}
          color="dark"
          size="small"
          onClick={(e) => {
            e.preventDefault();
            setInProgress(true);
            postFeedbacks(savedUser, feedBack)
              .then((result) => {
                if (result.status !== 200) {
                  return Promise.reject(result);
                }
                setModalState({ isVisible: false });
                userContext.toastHook.toast({
                  ...defaultToastState(),
                  message: "Your feedback was sent",
                  isOpen: true,
                });
              })
              .catch((e) => handleError(e, userContext))
              .finally(() => {
                setInProgress(false);
              });
          }}
        >
          Send
        </IonButton>
      </div>
    </main>
  );
}

function postFeedbacks(
  savedUser: SavedUser,
  feedback: string,
): Promise<HttpResponse> {
  return POST({
    url: `${JIBI_BASE_URL}/collections/users_feedbacks/records`,
    headers: {
      Authorization: savedUser.token,
    },
    body: {
      user: savedUser.user.id,
      feedback: feedback,
    },
  });
}

interface FeedbackModalProps {
  setModalState: React.Dispatch<React.SetStateAction<ModalStateType>>;
}
