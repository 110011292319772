import { HttpResponse } from "@capacitor/core";
import Letter from "../data/Letter";
import LettersResponse from "../data/LettersResponse";
import { SavedUser } from "../hooks/LoginHook";
import { GET, JIBI_BASE_URL, PATCH } from "../util/ApiClient";

export async function getLetters(
  matchId: string,
  savedUser: SavedUser,
): Promise<LettersResponse> {
  return GET({
    url: `${JIBI_BASE_URL}/v1/letter/${matchId}`,
    headers: {
      Authorization: savedUser.token,
    },
  }).then((response) => {
    if (response.status !== 200) {
      return Promise.reject(response);
    }
    return response.data as LettersResponse;
  });
}

export function patchLetter(
  savedUser: SavedUser,
  letter: Letter,
): Promise<HttpResponse> {
  return PATCH({
    url: `${JIBI_BASE_URL}/v1/letter/${letter.id}`,
    headers: {
      Authorization: savedUser.token,
    },
    body: {
      read: letter.read,
    },
  });
}
