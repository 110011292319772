import { Config } from "../data/Config";
import { PreferencesData } from "../data/PreferencesData";

export default interface CacheHook {
  updatePreferencesData: (preferencesData: PreferencesData) => void;
}

export interface InMemoryCache {
  appConfig: Config;
  preferencesData: PreferencesData;
}

export type CacheHookInternals = {
  updatePreferencesData: (preferencesData: PreferencesData) => void;
};

export function useCacheHook(
  cacheHookInternals: CacheHookInternals,
): CacheHook {
  return {
    updatePreferencesData: (preferencesData) => {
      cacheHookInternals.updatePreferencesData(preferencesData);
    },
  };
}
