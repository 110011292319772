import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import { Preferences } from "@capacitor/preferences";
import { useQueryClient } from "@tanstack/react-query";
import User from "../data/User";
import { informativeError } from "../util/error";
import ErrorHook from "./error-hook";
import { UserStateType } from "./UserContextHook";

export default interface LoginHook {
  saveUser: (user: SavedUser) => Promise<SavedUser>;
  logoutUser: () => Promise<void>;
}

export interface SavedUser {
  isProfileComplete: boolean;
  token: string;
  user: User;
}

export type LoginHookInternals = {
  setCurrentUser: (state: UserStateType) => void;
  logoutUser: () => void;
};

export function useLoginHook(
  errorHook: ErrorHook,
  loginHookInternals: LoginHookInternals,
): LoginHook {
  const queryClient = useQueryClient();
  return {
    saveUser: async (user) => {
      await Preferences.set({
        key: USER_PREFERENCES,
        value: JSON.stringify(user, null, 0),
      });
      loginHookInternals.setCurrentUser({
        loggedIn: true,
        savedUser: user,
      });
      return user;
    },
    logoutUser: async () => {
      try {
        await FirebaseMessaging.deleteToken();
      } catch (e) {
        errorHook.logError(
          "E",
          informativeError(
            `Failed to delete token: ${(e as Error)?.message || "_empty"}`,
          ),
        );
      }

      await Preferences.remove({ key: USER_PREFERENCES });
      queryClient.invalidateQueries();
      loginHookInternals.setCurrentUser({ loggedIn: false });
    },
  };
}

export const USER_PREFERENCES = "user";
