import { useContext } from "react";
import MatchInfo from "../../components/MatchInfo";
import { UserContext } from "../../util/BetterDatesApp";
import { calculateAge } from "../../util/date-ext";

export default function PreviewProfile() {
  const userContext = useContext(UserContext);
  if (!userContext?.userState?.loggedIn) {
    return <></>;
  }
  const savedUser = userContext.userState.savedUser;

  return (
    <div className="flex h-full w-full px-4 pt-2">
      <MatchInfo
        match={{
          id: "",
          partyTwo: {
            id: savedUser.user.id,
            name: savedUser.user.name || "",
            avatar: savedUser.user.avatar || "",
            age: savedUser.user.birthday
              ? calculateAge(savedUser.user.birthday)
              : 0,
            hint: "",
            theme: savedUser.user.theme,
          },
          meta: {
            id: "",
            unlockedAt: "",
            expiresAt: "",
            locked: false,
            requestedLock: "",
            reported: {},
          },
          hasUnreadLetters: false,
        }}
        previewMode={true}
        onClick={() => {
          return;
        }}
        state={{
          profilePictureState: {
            state: "innocent",
            transform: () => {
              return;
            },
          },
        }}
      />
    </div>
  );
}
