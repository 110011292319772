const dateFormatter = new Intl.DateTimeFormat("en-US", {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
});

export function calculateAge(birthday: Date): number {
  const currentDate = new Date();
  let age = currentDate.getFullYear() - birthday.getFullYear();
  const monthDiff = currentDate.getMonth() - birthday.getMonth();
  // If the birthday hasn't occurred yet this year, subtract 1 from the age
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && currentDate.getDate() < birthday.getDate())
  ) {
    age--;
  }
  return age;
}

export function formatDateToHumanReadable(date: Date): string {
  return dateFormatter.format(date);
}

export function getDateFormatter(): Intl.DateTimeFormat {
  return dateFormatter;
}

export function formatPrettyTimeRemaining(futureDate: Date): string {
  const now = new Date();
  const diffInMs = futureDate.getTime() - now.getTime();

  if (diffInMs < 0) {
    return "";
  }

  const months = Math.floor(diffInMs / (1000 * 60 * 60 * 24 * 30));
  const days = Math.floor(
    (diffInMs % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24),
  );
  const hours = Math.floor(
    (diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

  const parts: string[] = [];

  if (months > 0) {
    parts.push(`${months} ${months === 1 ? "month" : "months"}`);
  }
  if (days > 0) {
    parts.push(`${days} ${days === 1 ? "day" : "days"}`);
  }
  if (hours > 0) {
    parts.push(`${hours} ${hours === 1 ? "hour" : "hours"}`);
  }
  if (minutes > 0 && months === 0) {
    // Only show minutes if less than a month away
    parts.push(`${minutes} ${minutes === 1 ? "minute" : "minutes"}`);
  }

  if (parts.length === 0) {
    return "less than a minute";
  }

  if (parts.length === 1) {
    return parts[0];
  }

  const lastPart = parts.pop();
  return `${parts.join(", ")} and ${lastPart}`;
}
